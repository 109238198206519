import * as ES6Promise from 'es6-promise';
ES6Promise.polyfill();

import "./styles/styles.scss";

import(/* webpackChunkName: "bootstrap-5" */ 'bootstrap');
import * as $ from "jquery";

async function loadSiteManager() {
  const sm = await import(/* webpackChunkName: "site-manager" */ './ts/SiteManager');
  sm.SiteManager.getInstance();
}

async function renderPartnerSlider() {
  const container = $('.partner-slider');
  if (container !== null) {
    const slider = await import(/* webpackChunkName: "slider" */ './ts/slider');
    slider.initSmoothScroller(container, {animationName: 'partnerscroll', animationSpeedFactor: 3, inverted: false});
  }
}

async function loadHsFormFixer() {
  const container = $('.newsletter');
  if (container !== null) {
    const fixer = await import(/* webpackChunkName: "hsFormFixer" */ './ts/hubspot-form-style-fixer');
    fixer.fixHubspotNewsletterFormStyle();
  }
}

loadSiteManager().then(() => {
  console.log('Site manager loaded');
});

$(window).on('load', () => {
  renderPartnerSlider().then(() => {
    console.log('Slider loaded');
  });

  loadHsFormFixer().then(() => console.log('Tried to fix hubspot form style'));
});